import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import BackToHome from "../components/BackToHome"
import Layout from "../components/Layout"
import "./AllPosts.css"
import Blog from "./Blog"


class AllPosts extends React.Component {

  render() {

    const { data } = this.props;


    return (
      <Layout className={`AllPostsPage`} hideHamburger={true}>
        <Blog data={data} postsLimit={Infinity} />
        <BackToHome />
      </Layout>
    )
  }

}


export default injectIntl(AllPosts)

export const pageQuery = graphql`
query {
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          date
          image
        }
        fields {
          slug
          authorId
        }
        excerpt
      }
    }
  }
}
`
